import React from "react"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import CustomNavTitle from "../../../components/CustomNavTitle"
import DefaultLayoutSidebar from "../../../components/DefaultLayoutSidebar"

import illustration2x from "../../../images/illustrations/illustration-transfusion-reaction-symptoms@2x.png"

import { theme } from "../../../styles/theme"

const HSCRecipients = ({ location }) => {
  return (
    <Layout>
      <SEO title="Specific Requirements - Allogeneic haematopoietic stem cell (HSC) transplant recipients" />

      <CustomNavTitle
        title="Specific Requirements"
        destination="/requirements/irradiation/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <h3 css={[theme.components.title, theme.components.titleRed]}>
            Allogeneic HSC transplant recipients
          </h3>

          <h4
            className="mt-gap-2"
            css={[theme.components.title, theme.components.titleSmall]}
          >
            Indication for irradiated components
          </h4>
          <ol>
            <li>
              Patients receiving allogeneic HSC transplant.
            </li>
            <li>
              If chronic GvHD is present or immunosuppressant therapy continues.
            </li>
          </ol>

          <h4
            className="mt-gap-2"
            css={[theme.components.title, theme.components.titleSmall]}
          >
            Duration of requirement
          </h4>
          <ol>
            <li>
              From the start of conditioning therapy and while on GvHD
              prophylaxis (usually 6 months post-transplant or until lymphocytes
              are &gt;1x10<sup>9</sup>/L).
            </li>
            <li>Indefinitely.</li>
          </ol>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default HSCRecipients
